<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="approveObj" />
        <b-row class="">
          <b-col cols="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="approveObj.leavingDate"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model.trim="approveObj.note"
              />
            </b-form-group>
          </b-col>
          <!--           
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Documents</label
              >
            </div>
            <div class="d-flex flex-wrap">
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
                style="width: 110px; height: 96px"
                @click="!docLoading && $refs.reqDocs.click()"
              >
                <b-spinner
                  v-if="docLoading"
                  small
                  label="Loading..."
                  variant="primary"
                />
                <feather-icon
                  v-else
                  icon="PlusIcon"
                  size="34"
                  class="text-primary"
                />
              </div>
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
                style="width: 110px; height: 96px; position: relative"
                v-for="(file, index) in docList"
                :key="index"
                @click="openFile(file)"
              >
                <b-avatar
                  size="20"
                  variant="primary"
                  style="position: absolute; top: -5px; right: -5px"
                  button
                  @click.stop="removeDoc(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-avatar>
                <feather-icon
                  :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
                  size="30"
                  class="text-primary"
                />
              </div>

              <input
                type="file"
                id="reqDocs"
                hidden
                ref="reqDocs"
                multiple
                @change="addDoc()"
                accept="image/*,.pdf"
              />
            </div>
          </b-col> -->

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              :disabled="request"
              block
              @click="approveReq()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Approve</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="rejectObj" />
        <b-row class="">
          <b-col cols="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="rej_note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model.trim="rejectObj.note"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              :disabled="request"
              block
              @click="rejectReq()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Reject </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="detailObj" />
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row>
        <b-col
          v-for="(item, ind) in statusOptions"
          :key="ind"
          xl="2"
          lg="3"
          md="3"
          sm="12"
          cols="12"
        >
          <b-button
            block
            :variant="
              currentStatus == item.value ? 'primary' : 'outline-primary'
            "
            class="mb-1 mb-md-0"
            @click="LoadData(item.value)"
          >
            <span class="align-middle">{{ item.text }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        class="mt-1"
        thead-tr-class="adjust-head"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        small
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>

        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
          <!-- <b-badge variant="light-primary">
            {{ (currentPage - 1) * perPage + data.index + 1 }}
          </b-badge> -->
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.st.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary" class="font-small-4">
                {{ data.item.st.name }}
              </b-badge>
            </b-link>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.item.st.father_name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary" class="mr-50">
                ID: {{ data.item.st.id }}
              </b-badge>
              <b-badge variant="light-primary">
                RFID: {{ data.item.st.rfID }}
              </b-badge>

              <b-badge
                v-if="reportDomain == 'myskoolhpgs'"
                variant="light-primary"
                class="ml-50"
              >
                EMP# {{ data.item.st.empCode }}
              </b-badge>
            </small>
            <b-link
              v-if="
                data.item.st.status.toLowerCase() == 'left' &&
                data.item.st.leaving_date
              "
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.st.leaving_date).toLocaleDateString(
                    "en-UK",
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    }
                  )
                }}
              </b-badge>
            </b-link>
            <b-link
              v-if="
                data.item.st.status.toLowerCase() == 'present' &&
                data.item.st.appointedOn
              "
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.st.appointedOn).toLocaleDateString(
                    "en-UK",
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    }
                  )
                }}
              </b-badge>
            </b-link>
          </b-media>
        </template>

        <template #cell(campus)="data">
          <b-badge variant="light-primary">
            {{ getCampus(data.item.st.campusID) }}
          </b-badge>
        </template>
        <template #cell(designation)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.designation }}
          </b-badge>
        </template>
        <template #cell(contact_number)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.contact_number }}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.status }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <template v-if="data.item.us.status == 'pending'">
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Approve"
                @click="openApprove(data.item.us, data.item.st)"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon"
                v-b-tooltip.hover.top
                title="Reject"
                @click="openReject(data.item.us, data.item.st)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
            <b-button
              v-else
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="View"
              @click="openDetails(data.item.us, data.item.st)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import RequestDetails from "./RequestDetails.vue";

export default {
  components: {
    RequestDetails,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      rights: {},
      statusOptions: [
        { text: "Pending", value: "pending" },
        { text: "Accepted", value: "accepted" },
        { text: "Rejected", value: "rejected" },
      ],
      campuses: [],
      dataLoading: false,
      currentStatus: "pending",
      items: [],
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        { label: "designation", key: "designation" },
        { label: "contact", key: "contact_number" },
        { label: "status", key: "status" },
        "actions",
      ],
      request: false,
      sidebarTitle: "",
      visibility: false,
      approveObj: {},
      docLoading: false,
      docList: [],
      visibility2: false,
      rejectObj: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      visibility3: false,
      detailObj: null,
      currentStd: {},
    };
  },
  created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //       hideFee: ch.hideFee,
    //       editCampus: ch.editCampus,
    //       disableImport: ch.disableImport,
    //       disableExport: ch.disableExport,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);

    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    // }

    // if (this.reportDomain == "myskoolhpgs") {
    //   this.fields.splice(3, 0, { label: "campus", key: "campus" });
    // }
    // this.LoadCampuses();
    this.LoadData("pending");
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },

    openApprove(item, std) {
      this.approveObj = { ...item };
      this.currentStd = { ...std };
      // if (item.approved_document)
      //   this.docList = item.approved_document.split(",");
      // else this.docList = [];

      let dt = std.appointedOn
        ? new Date(std.appointedOn).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        : "";
      this.sidebarTitle = `${std.name} - ${std.designation}<br/>${dt}`;
      this.visibility = true;
    },
    addDoc() {
      var axios = require("axios");
      if (this.$refs.reqDocs.files.length > 0) {
        let docs = this.$refs.reqDocs.files;
        this.docLoading = true;
        let formdata = new FormData();
        docs.forEach((el) => {
          formdata.append("file", el);
        });

        axios
          .post("https://upload.myskool.app", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.docList.push(el.path);
            });
            this.approveObj.approved_document = this.docList.join(",");
          })
          .catch((err) => {
            // console.log(err);
            this.docLoading = false;
          })
          .finally(() => (this.docLoading = false));
      }
    },
    removeDoc(index) {
      this.docList.splice(index, 1);
      this.approveObj.approved_document = this.docList.join(",");
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.approveObj.leavingDate) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckNote() {
      var elem = this.$refs["note"];
      if (this.approveObj.note.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async approveReq() {
      if (this.CheckDate() == true && this.CheckNote() == true) {
        let obj = { ...this.approveObj };
        obj.status = "accepted";
        obj.approvedBy = this.$store.state.userData.userID;
        // console.log(obj);

        this.request = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffRequests/" +
            obj.id +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            obj.campusID,
          body: obj,
          message: `Request approved successfully!`,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData(this.currentStatus);
          this.visibility = false;
        }
      }
    },
    openReject(item, std) {
      this.rejectObj = { ...item };

      let dt = std.appointedOn
        ? new Date(std.appointedOn).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        : "";
      this.sidebarTitle = `${std.name} - ${std.designation}<br/>${dt}`;
      this.visibility2 = true;
    },
    CheckReason() {
      var elem = this.$refs["rej_note"];
      if (this.rejectObj.note.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async rejectReq() {
      if (this.CheckReason() == true) {
        let obj = { ...this.rejectObj };
        obj.status = "rejected";
        obj.leavingDate = new Date().toJSON();
        obj.approvedBy = this.$store.state.userData.userID;
        // console.log(obj);

        this.request = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffRequests/" +
            obj.id +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            obj.campusID,
          body: obj,
          message: `Request rejected successfully!`,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData(this.currentStatus);
          this.visibility2 = false;
        }
      }
    },
    openDetails(item, std) {
      this.detailObj = { ...item };

      let dt = std.appointedOn
        ? new Date(std.appointedOn).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        : "";
      this.sidebarTitle = `${std.name} - ${std.designation}<br/>${dt}`;
      this.visibility3 = true;
    },

    async LoadData(st) {
      this.currentStatus = st;

      this.dataLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "StaffRequests/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&status=" +
          this.currentStatus,
        token: this.$store.state.userData.token,
      });
      // console.log(myitem);
      this.items = myitem;
      this.dataLoading = false;
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
  },
};
</script>
<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
